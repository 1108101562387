import {Component, OnInit} from '@angular/core';
import {CreateAttendantRequestDto, AttendantType} from "../../service/journey.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AppResourceNames} from "../../app.component";
import {JourneyService} from "../../service/journey.service-impl";
import {NameInputConfiguration} from "../form/name-input-configuration";
import {DefaultEmailInputConfiguration} from "../form/email-input-default-configuration";
import {DefaultPhoneInputConfiguration} from "../form/phone-input-default-configuration";
import {DefaultCapacityInputConfiguration} from "../form/number-input-default-configuration";
import {OriginInputConfiguration} from "../form/origin-input-configuration";

@Component({
  selector: 'attendant-registration',
  templateUrl: './attendant-registration.component.html',
  styleUrls: ['./attendant-registration.component.css']
})
export class AttendantRegistrationComponent implements OnInit {

  resourceNames = AppResourceNames;

  nameInputConfig = NameInputConfiguration.get()
  emailInputConfig = DefaultEmailInputConfiguration.get()
  phoneInputConfig = DefaultPhoneInputConfiguration.get()
  originInputConfig = OriginInputConfiguration.get()
  capacityInputConfig = DefaultCapacityInputConfiguration.get()

  journeyId!: string;
  type: AttendantType = AttendantType.CAR;
  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.journeyId = params[AppResourceNames.JOURNEY_ID];
    });

    this.form = this.formBuilder.group({
      name: null,
      email: null,
      phone: null,
      originDescription: null,
      originLocation: null,
      type: null,
      capacity: null,
    });
  }

  selectCar() {
    this.type = AttendantType.CAR;
    this.form.get('capacity')?.enable({emitEvent: true})
  }

  isCarSelected(): boolean {
    return this.type === AttendantType.CAR;
  }

  selectPublicTransport() {
    this.type = AttendantType.PUBLIC_TRANSPORT;
    this.form.get('capacity')?.disable({emitEvent: true})
  }

  isPublicTransportSelected() {
    return !this.isCarSelected();
  }


  submit() {
    const attendant: CreateAttendantRequestDto = {
      journeyId: this.journeyId,
      name: this.form.value.name,
      email: this.form.value.email,
      phone: this.form.value.phone,
      type: this.type,
      capacity: this.form.value.capacity,
      origin: {
        location: this.form.value.originLocation,
        description: this.form.value.originDescription
      }
    }
    this.journeyService.registerAttendant(attendant);
    this.back();
  }

  back() {
    if (this.journeyId) {
      this.router.navigate([AppResourceNames.JOURNEY, this.journeyId]).then(() => {
      });
    }
  }

}
