import { Component, OnInit } from '@angular/core';
import {AppResourceNames} from "../../app.component";
import {JourneyService} from "../../service/journey.service-impl";

@Component({
  selector: 'app-journey-starter',
  templateUrl: './journey-home.component.html',
  styleUrls: ['./journey-home.component.css']
})
export class JourneyHomeComponent implements OnInit {

  resourceNames = AppResourceNames;

  constructor(private journeyService: JourneyService) { }

  ngOnInit(): void {
  }

  getJourneyIds(): string[] {
    return this.journeyService.getAllJourneyIds();
  }
}
