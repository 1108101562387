import {Component, OnInit} from '@angular/core';
import {AttendantDto, AttendantType, UpdateAttendantRequestDto} from "../../service/journey.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormGroup, UntypedFormBuilder} from "@angular/forms";
import {AppResourceNames} from "../../app.component";
import {JourneyService} from "../../service/journey.service-impl";
import {NameInputConfiguration} from "../form/name-input-configuration";
import {DefaultEmailInputConfiguration} from "../form/email-input-default-configuration";
import {DefaultPhoneInputConfiguration} from "../form/phone-input-default-configuration";
import {LatLngLocation} from "../../classes/latLngLocation";
import {DefaultCapacityInputConfiguration} from "../form/number-input-default-configuration";
import {OriginInputConfiguration} from "../form/origin-input-configuration";

@Component({
  selector: 'app-attendant-editor',
  templateUrl: './attendant-editor.component.html',
  styleUrls: ['./attendant-editor.component.css']
})
export class AttendantEditorComponent implements OnInit {

  nameInputConfig = NameInputConfiguration.get()
  emailInputConfig = DefaultEmailInputConfiguration.get()
  phoneInputConfig = DefaultPhoneInputConfiguration.get()
  originInputConfig = OriginInputConfiguration.get()
  capacityInputConfig = DefaultCapacityInputConfiguration.get()

  type: AttendantType = AttendantType.UNKNOWN;

  journeyId!: string;

  attendant!: AttendantDto;

  form!: FormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.loadAttendant();
    this.initForm();
  }

  private loadAttendant() {
    this.route.params.subscribe(params => {
      this.journeyId = params[AppResourceNames.JOURNEY_ID];
      this.journeyService
        .loadAttendant(params[AppResourceNames.JOURNEY_ID], params[AppResourceNames.ATTENDANT_ID])
        .subscribe(response => {
          this.attendant = response;
        });
    });
  }

  private initForm() {
    if (!this.attendant) {
      return;
    }

    this.form = this.formBuilder.group({
      name: this.attendant.name,
      email: this.attendant.email,
      phone: this.attendant.phone,
      capacity: this.attendant.capacity,
    });
    this.type = this.attendant.type
  }

  submit() {
    const attendant: UpdateAttendantRequestDto = {
      id: this.attendant.id,
      journeyId: this.journeyId,
      name: this.form.value.name,
      email: this.form.value.email,
      phone: this.form.value.phone,
      type: this.type,
      capacity: this.form.value.capacity,
    }

    try {
      this.journeyService.updateAttendant(attendant);
      this.back();
    } catch (e) {
      console.error(e)
    }
  }

  back() {
    this.router.navigate([
      AppResourceNames.JOURNEY,
      this.journeyId,
      AppResourceNames.ATTENDANT,
      this.attendant.id
    ]).then();
  }

  selectCar() {
    this.type = AttendantType.CAR;
  }

  isCarSelected(): boolean {
    return this.type === AttendantType.CAR;
  }

  selectPublicTransport() {
    this.type = AttendantType.PUBLIC_TRANSPORT;
  }

  isPublicTransportSelected() {
    return this.type === AttendantType.PUBLIC_TRANSPORT;
  }
}
