import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {NotFoundError, Observable, of} from 'rxjs';
import {
  UpdateAttendantRequestDto,
  CreateAttendantRequestDto,
  AttendantType,
  AttendantDto,
  RegisterParticipantRequestDto,
  CreateJourneyRequestDto,
  UpdateJourneyRequestDto,
  JourneyDto,
  JourneyServiceInterface,
  UpdateParticipantRequestDto,
  UnattendedParticipantListDto,
  StopoverDto,
  JoinerDto,
  MarkersDto,
  AttendeeMarker,
  StopoverMarker,
  ParticipantDto,
  CreateStopoverRequestDto,
  RouteDto,
  UpdateStopoverRequestDto,
  DestinationMarker, DestinationDto,
} from "../journey.service";

import * as journeys from './journeys.json';

@Injectable({
  providedIn: 'root'
})
export class MockedJourneyService implements JourneyServiceInterface {

  journeySequence = 100;
  journeys: Journey[] = Array.from(journeys) as any;

  getAllJourneyIds(): string[] {
    const keys: string[] = [];
    this.journeys.forEach((journey: Journey) => {
      keys.push(journey.id);
    })
    return keys;
  }

  createJourney(journeyDto: CreateJourneyRequestDto): string {

    const journey: Journey = {
      id: "" + this.journeySequence++,
      title: journeyDto.title,
      description: journeyDto.description,
      address: journeyDto.address,
      location: journeyDto.location,
      dateTime: journeyDto.dateTime,
      organizer: {
        name: journeyDto.organizer.name,
        email: journeyDto.organizer.email,
        phone: journeyDto.organizer.phone
      },
      attendants: [],
      participants: []
    }

    this.journeys.push(journey);
    return journey.id;
  }

  getJourney(journeyId: string): Observable<JourneyDto> {
    const journey = this.findJourney(journeyId);
    const overview: JourneyDto = {
      id: journey.id,
      title: journey.title,
      description: journey.description,
      dateTime: journey.dateTime,
      address: journey.address,
      location: journey.location,
      organizer: {
        name: journey.organizer.name,
        email: journey.organizer.email,
        phone: journey.organizer.phone
      },
      attendants: [],
      participants: [],
    }

    // Calculate Participants
    journey.participants.forEach((participant: Participant) => {
      const model: ParticipantDto = {
        id: participant.id,
        journeyId: journeyId,
        contact: participant.contact,
        state: participant.state,
        origin: participant.origin,
      };
      overview.participants.push(model);
    });

    // Calculate Attendants
    journey.attendants.forEach((attendant: Attendant) => {
      const model: AttendantDto = {
        id: attendant.id,
        journeyId: journeyId,
        name: attendant.contact.name,
        email: attendant.contact.email,
        phone: attendant.contact.phone,
        type: attendant.type,
        capacity: attendant.capacity,
      };
      overview.attendants.push(model);
    });

    return of(overview);
  }

  getJourneyMarkers(journeyId: string): Observable<MarkersDto> {
    const journey = this.findJourney(journeyId);
    if(!journey)
      throw new Error('No journey found for ID ' + journeyId);

    const markers: MarkersDto = {
      destination: {
        description: journey.address,
        location: {
          lat: journey.location ? journey.location?.lat : 0,
          lng: journey.location ? journey.location?.lng : 0
        }
      },
      attendees: [],
      stopovers: []
    };

    journey.participants.forEach(attendee => {
      const aMarker: AttendeeMarker = {
        description: attendee.contact.name,
        location: {
          lat: attendee.origin ? attendee.origin.lat : 0,
          lng: attendee.origin ? attendee.origin.lng : 0
        },
        state: "SEARCHING"
      }
      markers.attendees.push(aMarker)
    })

    journey.attendants.forEach(attendant => {
      attendant.stopovers.forEach(stopover => {
        if(stopover.location) {
          const marker: StopoverMarker = {
            description: attendant.contact.name,
            location: stopover.location,
            dateTime: stopover.dateTime ?? "-00:00",
          }
          markers.stopovers.push(marker)
        }
      })
    })

    return of(markers)
  }

  updateJourney(journeyData: UpdateJourneyRequestDto): void {
    const journey = this.findJourney(journeyData.id);
    journey.id = journeyData.id;
    journey.title = journeyData.title;
    journey.description = journeyData.description;
    journey.dateTime = journeyData.dateTime;
    journey.address = journeyData.address;
    journey.location = journeyData.location;
    journey.organizer = {
      name: journeyData.organizer.name,
      email: journeyData.organizer.email,
      phone: journeyData.organizer.phone
    }
  }

  registerParticipant(participant: RegisterParticipantRequestDto): string {
    const journey = this.findJourney(participant.journeyId);

    const model: Participant = {
      id: "" + ++this.journeySequence,
      contact: participant.contact,
      origin: participant.origin,
      state: "CONFIRMED"
    }

    journey.participants.push(model);
    return model.id;
  }

  loadParticipant(journeyId: string, participantId: string): Observable<ParticipantDto> {
    const participant = this.findParticipant(journeyId, participantId);
    return this.mapParticipantToParticipantDto(journeyId, participant);
  }

  updateParticipant(participant: UpdateParticipantRequestDto): void {
    const _ = this.findParticipant(participant.journeyId, participant.id);
    _.contact = participant.contact;
    _.origin = participant.origin;
  }

  // -------------------------------------------------------------------- JOURNEY - ATTENDANT

  registerAttendant(attendant: CreateAttendantRequestDto): string {
    const journey = this.findJourney(attendant.journeyId);
    const stopover: Stopover = {
      id: "1",
      location: attendant.origin.location,
      description: attendant.origin.description,
      dateTime: null,
      joiners: []
    }
    const _: Attendant = {
      id: "" + this.getRandom(),
      state: "REGISTERED",
      contact: {
        name: attendant.name,
        email: attendant.email,
        phone: attendant.phone
      },
      type: attendant.type,
      capacity: attendant.capacity ?? -1,
      stopovers: [stopover],
    };
    journey.attendants.push(_);
    return _.id;
  }

  getRoute(journeyId: string, attendantId: string, routeId: string): Observable<RouteDto> {
    const attendant = this.findAttendant(journeyId, attendantId)
    const journey = this.findJourney(journeyId)

    const destination: DestinationDto = {
      description: journey.address,
      location: journey.location,
      isoDateTime: journey.dateTime
    }

    const stopovers: StopoverDto[] = [];
    attendant.stopovers.forEach((stopover: Stopover) => {
      console.info("Stopover: " + JSON.stringify(stopover))
      const joiners: JoinerDto[] = [];
      stopover.joiners.forEach((joiner: Joiner) => {
        const participant = this.findParticipant(journeyId, joiner.participantId);
        joiners.push({
          participantId: joiner.participantId,
          displayName: participant.contact.name
        })
      })
      const tmpStop: StopoverDto = {
        id: stopover.id,
        attendantId: attendantId,
        journeyId: journeyId,
        location: stopover.location,
        description: stopover.description,
        isoDateTime: stopover.dateTime,
        joiners: joiners,
      }
      stopovers.push(tmpStop);
    });
    // Order by Time
    stopovers.sort(function(a,b){
      if(a.isoDateTime == null || b.isoDateTime == null) {
        return 0
      }
      return new Date(a.isoDateTime).getTime() - new Date(b.isoDateTime).getTime();
    });


    const route: RouteDto =  {
      id: routeId,
      journeyId: journeyId,
      attendantId: attendantId,
      stopovers: stopovers,
      destination: destination
    }

    return of(route);
  }

  getRouteMarkers(journeyId: string, attendantId: string, routeId: string): Observable<MarkersDto> {
    const journey = this.findJourney(journeyId);

    const destinationMarker: DestinationMarker = {
      location: journey.location,
      description: journey.address
    }

    const attendant = this.findAttendant(journeyId, attendantId)
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const stopoverMarkers: StopoverMarker[] = [];
    attendant.stopovers.forEach((stopover: Stopover) => {
      let dateTime: string | null = null
      if(stopover.dateTime != null) {
        dateTime = new Date(stopover.dateTime).toLocaleString("de-CH")
      }

      const marker: StopoverMarker = {
        location: stopover.location,
        description: stopover.description,
        dateTime: dateTime
      }
      stopoverMarkers.push(marker);
    });

    const routeMarkers: MarkersDto = {
      destination: destinationMarker,
      stopovers: stopoverMarkers,
      attendees: []
    }

    return of(routeMarkers);
  }


  loadAttendant(journeyId: string, attendantId: string): Observable<AttendantDto> {
    const attendant = this.findAttendant(journeyId, attendantId);
    console.info("loadAttendant: Entity=" + JSON.stringify(attendant))
    const dto: AttendantDto = {
      id: attendantId,
      journeyId: journeyId,
      name: attendant.contact.name,
      email: attendant.contact.email,
      phone: attendant.contact.phone,
      type: attendant.type,
      capacity: attendant.capacity,
    }
    console.info("loadAttendant: DTO=" + JSON.stringify(dto))
    return of(dto);
  }

  updateAttendant(attendant: UpdateAttendantRequestDto): void {
    console.info("updateAttendant: DTO=" + JSON.stringify(attendant))
    const _attendant = this.findAttendant(attendant.journeyId, attendant.id);
    if (_attendant) {
      _attendant.contact.name = attendant.name
      _attendant.contact.email = attendant.email
      _attendant.contact.phone = attendant.phone
      _attendant.type = attendant.type ?? _attendant.type
      _attendant.capacity = attendant.capacity ?? _attendant.capacity
    }
    console.info("updateAttendant: Attendant=" + JSON.stringify(_attendant))
  }

  // ---------------------------------------------- JOURNEY - ATTENDANT - ROUTE - STOPOVER

  createStopover(stopover: CreateStopoverRequestDto): string {
    const attendant = this.findAttendant(stopover.journeyId, stopover.attendantId);
    const model: Stopover = {
      id: this.getRandom(),
      location: stopover.location,
      dateTime: stopover.isoDateTime,
      description: stopover.description,
      joiners: []
    };
    attendant.stopovers.push(model);
    return model.id;
  }

  readStopover(journeyId: string, attendantId: string, stopoverId: string): Observable<StopoverDto> {
    const stop = this.findStopover(journeyId, attendantId, stopoverId);
    const dto: StopoverDto = {
      id: stop.id,
      attendantId: attendantId,
      journeyId: journeyId,
      location: stop.location,
      isoDateTime: stop.dateTime,
      description: stop.description,
      joiners: []
    }
    return of(dto);
  }

  updateStopover(stopover: UpdateStopoverRequestDto): void {
    const model = this.findStopover(stopover.journeyId, stopover.attendantId, stopover.id);
    model.dateTime = stopover.isoDateTime;
    model.description = stopover.description;
    model.location = stopover.location
  }

  deleteStopover(journeyId: string, attendantId: string, stopId: string): void {
    const model = this.findAttendant(journeyId, attendantId);
    model.stopovers = model.stopovers.filter((stop: Stopover) => stop.id !== stopId)
  }

  loadUnattendedParticipants(journeyId: string): Observable<UnattendedParticipantListDto> {
    const journey = this.findJourney(journeyId);

    const attendeesWithRide: string[] = [];
    journey.attendants.forEach((attendant: Attendant) => {
      attendant.stopovers.forEach((stop: Stopover) => {
        stop.joiners.forEach((join:Joiner) => {
          attendeesWithRide.push(join.participantId);
        })
      })
    });

    const attendeesWithoutRide: UnattendedParticipantListDto = {entities: []};
    journey.participants.forEach((attendee: Participant) => {
      if(!attendeesWithRide.includes(attendee.id)) {
        attendeesWithoutRide.entities.push({
          id: attendee.id,
          displayName: attendee.contact.name,
        })
      }
    })

    return of(attendeesWithoutRide);
  }

  createJoiner(journeyId: string, attendantId: string, stopId: string, participantId: string): void {
    const stop = this.findStopover(journeyId, attendantId, stopId);
    stop.joiners.push({
      participantId: participantId
    })
  }

  deleteJoiner(journeyId: string, attendantId: string, stopId: string, attendeeId: string): void {
    const stop = this.findStopover(journeyId, attendantId, stopId);
    for (let i = 0; i < stop.joiners.length; i++) {
      if (stop.joiners[i].participantId === attendeeId) {
        stop.joiners.splice(i, 1);
      }
    }
  }













  private findJourney(id: string): Journey {
    const journey = this.journeys.find((journey: Journey) => id === journey.id);
    if (!journey) {
      throw new NotFoundError("Journey with ID '" + id + "' not found!");
    }
    return journey;
  }

  private findParticipant(journeyId: string, participantId: string): Participant {
    const journey = this.findJourney(journeyId);
    const participant = journey.participants.find((p: Participant) => participantId === p.id);
    if (!participant) {
      throw new NotFoundError("Attendant with ID '" + participantId + "' not found in Journey with ID '" + journeyId + "'");
    }
    return participant;
  }

  private mapParticipantToParticipantDto(journeyId: string, participant: Participant): Observable<ParticipantDto> {
    const dto: ParticipantDto = {
      id: participant.id,
      journeyId: journeyId,
      contact: participant.contact,
      origin: participant.origin,
      state: participant.state
    }
    return of(dto);
  }

  private findAttendant(journeyId: string, attendantId: string): Attendant {
    const journey = this.findJourney(journeyId);
    const attendant = journey.attendants.find((attendant: Attendant) => attendantId === attendant.id);
    if (!attendant) {
      throw new NotFoundError("Attendant with ID '" + attendantId + "' not found in Journey with ID '" + journeyId + "'");
    }
    return attendant;
  }

  private findStopover(journeyId: string, attendantId: string, stopId: string): Stopover {
    const attendant = this.findAttendant(journeyId, attendantId);
    const stop = attendant.stopovers.find((stop: Stopover) => stopId === stop.id);
    if (!stop) {
      throw new NotFoundError("Stop with ID '" + attendantId + "' not found in Journey with ID '" + journeyId + "'");
    }
    return stop;
  }

  private getRandom(): string {
    return "" + Math.floor(Math.random() * (9999 - 1000) + 1000);
  }
}


// ============================================== MODEL

export interface Journey {
  id: string;
  title: string;
  description: string | null;
  dateTime: string;
  address: string | null;
  location: Location | null;
  organizer: Contact;
  participants: Participant[];
  attendants: Attendant[];
}

export interface Contact {
  name: string;
  email: string | null;
  phone: string | null;
}

export interface Participant {
  id: string;
  state: string;
  contact: Contact;
  origin: Location | null;
}

export interface Attendant {
  id: string;
  state: string;
  contact: Contact;
  //origin: Location | null
  type: AttendantType;
  capacity: number;
  stopovers: Stopover[];
}

export interface Stopover {
  id: string;
  location: Location | null
  description: string | null;
  dateTime: string | null;
  joiners: Joiner[]
}

export interface Joiner {
  participantId: string;
}

export interface Location {
  lat: number;
  lng: number;
}
