import {Component, forwardRef, Injectable, Input, LOCALE_ID, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup, NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import {
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerI18nDefault,
  NgbDateStruct,
  NgbTimeStruct
} from "@ng-bootstrap/ng-bootstrap";


@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split(".");
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return {
          day: this.toInteger(dateParts[0]),
          month: 0,
          year: 0
        };
      } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return {
          day: this.toInteger(dateParts[0]),
          month: this.toInteger(dateParts[1]),
          year: 0
        };
      } else if (dateParts.length === 3 && this.isNumber(dateParts[0])
        && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
        return {
          day: this.toInteger(dateParts[0]),
          month: this.toInteger(dateParts[1]),
          year: this.toInteger(dateParts[2])
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date
      ? `${this.isNumber(date.day) ? this.padNumber(date.day) : ""}.${this.isNumber(date.month) ? this.padNumber(date.month) : ""}.${date.year}`
      : "";
  }

  private toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  private isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }

  private padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return "";
    }
  }
}



@Component({
  selector: 'date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: NgbDatepickerI18n,
      useClass: NgbDatepickerI18nDefault
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    },
  ]
})
export class DateTimeComponent implements OnInit, ControlValueAccessor, Validator {

  @Input()
  disabled: boolean = false

  @Input()
  dateDisabled: boolean = false

  @Input()
  timeDisabled: boolean = false

  @Input()
  required: boolean = false

  @Input()
  label: string = "Datum & Zeit"

  @Input()
  description: string = ""

  @Input()
  invalidPatternFeedback: string = "Datum muss im Format TT.MM.JJJJ, bspw. 23.04.2023 angegeben werden."

  @Input()
  invalidMinDateFeedback: string = "Das Datum darf nicht vor %minDate% liegen."

  @Input()
  invalidMaxDateFeedback: string = "Das Datum darf nicht nach %maxDate% liegen."

  @Input()
  daysInFuture: number = 90

  minDateStruct!: NgbDateStruct;
  maxDateStruct!: NgbDateStruct;

  formGroup!: FormGroup

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      date: new FormControl<NgbDateStruct | null>(null, Validators.required),
      time: new FormControl<NgbTimeStruct | null>(null, Validators.required),
    });

    if(this.disabled || this.dateDisabled) {
      this.formGroup.get('date')?.disable()
    }
    if(this.disabled || this.timeDisabled) {
      this.formGroup.get('time')?.disable()
    }

    const now = new Date();
    this.minDateStruct = {
      day: now.getDate(),
      month: now.getMonth() + 1,
      year: now.getFullYear()
    }

    const limit = new Date()
    limit.setDate(limit.getDate() + this.daysInFuture)
    this.maxDateStruct = {
      day: limit.getDate(),
      month: limit.getMonth() + 1,
      year: limit.getFullYear()
    }
  }
  isDateDisabled() {
    //console.info("isDateDisabled? " + this.formGroup.get('date')?.disabled)
    return this.formGroup.get('date')?.disabled
  }

  isValid(): boolean {
    const control = this.formGroup;
    if(!control || this.formGroup.get('date')?.disabled)
      return false
    return control.valid && control.touched
  }

  isInvalid(): boolean {
    const control = this.formGroup;
    if(!control || this.formGroup.get('date')?.disabled)
      return false
    return control.invalid && control.touched
  }

  get invalidFeedback() {
    //console.info("invalidFeedback()")
    if(this.formGroup.valid) {
      return ''
    }

    if(this.formGroup.hasError('pattern')) {
      console.info("hasError: pattern")
      return this.invalidPatternFeedback
    }

    if(this.formGroup.hasError('past')) {
      return this.invalidMinDateFeedback
    }

    if(this.formGroup.hasError('future')) {
      return this.invalidMaxDateFeedback
    }
  }

  // ControlValueAccessor ------------------------------------------------------

  _onChange = (value: any | null) => {};

  registerOnChange(fn: (value: any | null) => void): void {
    this.formGroup.valueChanges.subscribe(fn);
    this.formGroup.valueChanges.subscribe(value => {
      this.onChange(value);
    });
    this._onChange = fn;
  }

  onChange(value: any) {
    //console.info("onChange: " + value)
  }

  _onTouched = () => {};

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable()
    } else {
      this.formGroup.enable()
      if(this.dateDisabled) {
        this.formGroup.get('date')?.disable()
      }
      if(this.timeDisabled) {
        this.formGroup.get('time')?.disable()
      }
    }
  }

  writeValue(value: any | null): void {
    //console.info("writeValue: " + value)
    if(value) {
      this.formGroup.setValue(value, {emitEvent: false})
    }
  }

  // Validator ---------------------------------------------------------------

  onValidationChange: any = () => {
  };

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    //console.info("validate(): " + JSON.stringify(control.value))

    if(this.formGroup.valid) {
      return null
    }

    const v = control.value

    if(!v || !v.date || !v.date.day) {
      this.formGroup.setErrors({ pattern: "date is not parsable" })
      return { pattern: "date is not parsable" }
    }

    if((""+v.date.year).length != 4) {
      this.formGroup.setErrors({ pattern: "year is not 4 digits long" })
      return { pattern: "year is not 4 digits long" }
    }

    const date: Date = new Date();

    date.setFullYear(v.date.year, v.date.month - 1, v.date.day)
    date.setHours(v.time.hour, v.time.minute, v.time.second, 0)

    const now: Date = new Date()
    const future: Date = new Date()
    future.setDate(future.getDate() + this.daysInFuture)

    if(date.getTime() - now.getTime() < 0) {
      this.formGroup.setErrors({ past: "date has to be after " + now.toISOString() })
      return { past: "date has to be after " + now.toISOString() }
    }
    if(date.getTime() - future.getTime() > 0) {
      this.formGroup.setErrors({future: "date has to be before " + future.toISOString() })
      return { future: "date has to be before " + future.toISOString() }
    }

    if(this.formGroup.controls['date'].errors) {
      return this.formGroup.controls['date'].errors
    }
    if(this.formGroup.controls['time'].errors) {
      return this.formGroup.controls['time'].errors
    }

    return null;
  }
}
