<div class="container">
  <p><a [routerLink]="[resourceNames.JOURNEY, 'start']">Start</a></p>
</div>
<div class="container">
  <p>TODO: read from local storage. Every loaded journeyID should be written to local storage for easy access.</p>
</div>
<div class="container" *ngFor="let id of getJourneyIds()">
  <p><a [routerLink]="[resourceNames.JOURNEY, id]">show {{id}}</a></p>
</div>
<div class="container">
  <p><a [routerLink]="['form-test']">Test From</a></p>
</div>
