import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AppResourceNames} from "../../app.component";
import {RegisterParticipantRequestDto} from "../../service/journey.service";
import {JourneyService} from "../../service/journey.service-impl";
import {NameInputConfiguration} from "../form/name-input-configuration";
import {DefaultEmailInputConfiguration} from "../form/email-input-default-configuration";
import {DefaultPhoneInputConfiguration} from "../form/phone-input-default-configuration";

@Component({
  selector: 'participant-registration',
  templateUrl: './participant-registration.component.html',
  styleUrls: ['./participant-registration.component.css']
})
export class ParticipantRegistrationComponent implements OnInit {

  nameInputConfig = NameInputConfiguration.get()
  emailInputConfig = DefaultEmailInputConfiguration.get()
  phoneInputConfig = DefaultPhoneInputConfiguration.get()

  journeyId!: string;

  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.journeyId = params[AppResourceNames.JOURNEY_ID];
    });

    this.form = this.formBuilder.group({
      name: null,
      email: null,
      phone: null,
      origin: null
    });
  }

  submit() {
    const dto: RegisterParticipantRequestDto = {
      journeyId: this.journeyId,
      contact: {
        name: this.form.value.name,
        email: this.form.value.email,
        phone: this.form.value.phone,
      },
      origin: this.form.value.origin,
    }
    this.journeyService.registerParticipant(dto);
    this.back();
  }

  back() {
    if (this.journeyId) {
      this.router.navigate([AppResourceNames.JOURNEY, this.journeyId]).then(() => {
      });
    }
  }
}
