<div>
  <h4><a [routerLink]="['../..']"><i class="bi bi-arrow-left"></i></a></h4>
</div>

<h4>Begleiter</h4>

<ng-template [ngIf]="attendant">
  <div class="position-relative pt-2">
    <h1>{{attendant.name}}</h1>
    <h4>
        <span class="position-absolute end-0">
          <a [routerLink]="['edit']"><i class="bi bi-pencil-square"></i></a>
        </span></h4>
  </div>

  <div class="pt-2">
    <ng-container *ngIf="attendant.email">
      <p><i class="bi bi-at"></i>&nbsp;<a href="mailto:{{attendant.email}}">{{attendant.email}}</a><p>
    </ng-container>
    <ng-container *ngIf="attendant.phone">
      <p><i class="bi bi-phone"></i> {{attendant.phone}}</p>
    </ng-container>
  </div>
<!--
  <ng-container *ngIf="attendant.origin">
    <div class="pt-2">
      <map-viewer [journeyId]="attendant.journeyId"
                  [center]="attendant.origin"
                  [mapHeight]="400">
      </map-viewer>
    </div>
  </ng-container>
-->

<!--
  <div class="position-relative pt-2">
    <h2>{{attendant.name}}</h2>
    <h4>
      <span class="position-absolute end-0">
        <a [routerLink]="['edit']"><i class="bi bi-pencil-square"></i></a>
      </span></h4>
  </div>

  <div class="pt-2">
    <p><i class="bi bi-at"></i>&nbsp;<a href="mailto:{{attendant.email}}">{{attendant.email}}</a><p>
    <p><i class="bi bi-phone"></i> {{attendant.phone}}</p>
  </div>
-->

  <div class="mt-4 pt-2 position-relative">
    <h2>
      Treffpunkte
    </h2>
    <h4>
      <span class="position-absolute end-0">
        <a [routerLink]="[resourceNames.STOPOVER, 'add']"><i class="bi bi-plus-square"></i></a>
        <!--<a [routerLink]="[resourceNames.STOPOVER, 'add']">Treffpunkt hinzufügen</a>-->
      </span>
    </h4>
  </div>

  <div *ngFor="let stopover of route.stopovers" style="font-size: large">
    <div style="display: flex;">
      <div style="width: 40px; height: 100%; float: left; text-align:center;" class="p-2">
        <i class="bi bi-geo-alt"></i>
      </div>

      <div style="width: auto;" class="p-2">
        <ng-template [ngIf]="stopover.isoDateTime == null">
          <b style="color: red">Zeit?</b> - {{stopover.description}}
        </ng-template>
        <ng-template [ngIf]="stopover.isoDateTime != null">
          <b>{{stopover.isoDateTime | date:'HH:mm' }}</b> - {{stopover.description}}
        </ng-template>
      </div>

      <div style="width: 80px; padding-left: 10px " class="p-2">
        <a [routerLink]="[resourceNames.STOPOVER, stopover.id, 'edit']"><i class="bi bi-pencil"></i></a> &nbsp;
        <ng-template [ngIf]="stopover.joiners.length > 0 && route.stopovers.length > 1">
          <i class="bi bi-x-circle"></i>
        </ng-template>
        <ng-template [ngIf]="stopover.joiners.length == 0 && route.stopovers.length > 1">
          <a role="button" class="text-primary" (click)="removeStopover(stopover.id)"><i class="bi bi-x-circle"></i></a>
        </ng-template>
      </div>
    </div>


    <div style="display: flex;">
      <div style="width: 40px; display: flex;">
        <span style="position: relative; height: 100%; border-left: 2px dotted black; left: 50%; margin-left: -1px;"></span>
      </div>
      <div style="width: auto;">
        <ul class="list-group">
          <ng-container *ngIf="stopover.joiners && stopover.joiners.length > 0">
          <!--<div *ngIf="stop.joins && stop.joins.length > 0">-->
            <ng-container *ngFor="let join of stopover.joiners">
              <li class="list-group-item border-0 p-1">
                <i class="bi bi-person-fill"></i> {{join.displayName}}
                <!-- TODO - replace sub-resource attendee of stop -->
                <a role="button" class="text-primary" (click)="removeJoiner(stopover.id, join.participantId)">
                  <i class="bi bi-x-circle"></i></a>
              </li>
            </ng-container>
            <!--</div>-->
          </ng-container>
          <li class="list-group-item border-0 pt-1 pb-3">
            <!-- TODO - replace sub-resource attendee of stop -->
            <a [routerLink]="[resourceNames.STOPOVER, stopover.id,'join']">hinzufügen</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div style="display: flex;font-size: large">
    <div style="width: 40px; height: 100%; float: left; text-align:center;" class="p-2">
      <i class="bi bi-geo-alt-fill"></i>
    </div>
    <div style="width: auto;" class="p-2">
      <b>{{route.destination.isoDateTime | date:'HH:mm' }}</b> - {{route.destination.description}}
    </div>
  </div>

  <h2 class="pt-5">Map</h2>
  <map-viewer [markers]="markers"></map-viewer>

</ng-template>

<!--
<app-debug [object]="attendant"></app-debug>
-->
