import {Component, Input, OnInit} from '@angular/core';
import {MarkersDto, LocationDto} from "../../../service/journey.service";
import * as Leaflet from "leaflet";
import {JourneyService} from "../../../service/journey.service-impl";

@Component({
  selector: 'map-viewer',
  templateUrl: './map-viewer.component.html',
  styleUrls: ['./map-viewer.component.css']
})
export class MapViewerComponent implements OnInit {

  @Input()
  markers: MarkersDto | null = null;

  @Input()
  center: LocationDto | null = null

  @Input()
  mapHeight: number = 200

  map!: Leaflet.Map;

  options = {
    layers: [
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      })
    ],
    zoomControl: false,
    scrollWheelZoom: false,
    dragging: false,
    //zoom: 16,
    zoom: 18,
    center: { lat: 47.39432396409528, lng: 8.536281932054637 }
  }

  greenIcon = new Leaflet.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  blueIcon = new Leaflet.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  redIcon = new Leaflet.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  greyIcon = new Leaflet.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  constructor(private journeyService: JourneyService) {
  }

  ngOnInit(): void {
    if(this.center) {
      this.options.center = this.center
    }
  }

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.initMarkers();
  }

  initMarkers() {
    const bounds = this.map.getBounds();

    // DESTINATION
    if(this.markers && this.markers.destination.location) {
      const marker = Leaflet.marker(
        this.markers.destination.location,
        {
          icon: this.greenIcon,
          draggable: false,
        }
      );
      marker.addTo(this.map).bindPopup(this.markers.destination.description ?? "unknown");
      bounds.extend(this.markers.destination.location)
    }

    // PARTICIPANTS
    if(this.markers) {
      this.markers.attendees.forEach(attendee => {
        if (!attendee.location || attendee.location.lat == 0) {
          return
        }
        const marker = Leaflet.marker(
          attendee.location,
          {
            icon: this.blueIcon,
            draggable: false,
          }
        );
        marker.addTo(this.map).bindPopup(attendee.description);
        bounds.extend(attendee.location)
      })
    }

    // STOPOVERS
    if(this.markers) {
      this.markers.stopovers.forEach(stopover => {
        if(!stopover.location || stopover.location.lat == 0) {
          return
        }
        const marker = Leaflet.marker(
          stopover.location,
          {
            icon: this.greyIcon,
            draggable: false,
          }
        );
        marker.addTo(this.map).bindPopup("" + stopover.dateTime + " - " + stopover.description);
        bounds.extend(stopover.location)
      })
    }

    if(!this.center) {
      this.map.fitBounds(
        bounds.pad(0.4),
        {
          paddingTopLeft: [0,40]
        }
      )
    }
  }
}
