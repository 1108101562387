import {Component, OnInit} from '@angular/core';
import * as Leaflet from 'leaflet';
import {MarkersDto, JourneyDto} from "../../service/journey.service";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {JourneyService} from "../../service/journey.service-impl";
import {AppResourceNames} from "../../app.component";

Leaflet.Icon.Default.imagePath = 'assets/';

@Component({
  selector: 'journey-map',
  templateUrl: './journey-map.component.html',
  styleUrls: ['./journey-map.component.css']
})
export class JourneyMapComponent implements OnInit {

  resourceNames = AppResourceNames;

  id: string | null = null;
  journey: JourneyDto | undefined;

  markers: MarkersDto | undefined;

  map!: Leaflet.Map;
  options = {
    layers: [
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      })
    ],
    zoomControl: true,
    dragging: true,
    zoom: 16,
    center: { lat: 47.39432396409528, lng: 8.536281932054637 }
  }

  greenIcon = new Leaflet.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  blueIcon = new Leaflet.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  redIcon = new Leaflet.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  greyIcon = new Leaflet.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  constructor(
    private journeyService: JourneyService,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get(AppResourceNames.JOURNEY_ID)
      if (this.id) {
        this.journeyService.getJourney(this.id).subscribe(response => {
          this.journey = response;
        });
      }
    })
  }

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    this.initMarkers();
  }

  initMarkers() {
    if(!this.id) {
      return;
    }
    this.journeyService.getJourneyMarkers(this.id).subscribe(response => {
      this.markers = response;
    })

    const bounds = this.map.getBounds();

    // DESTINATION
    if(this.markers && this.markers.destination.location) {
      const marker = Leaflet.marker(
        this.markers.destination.location,
        {
          icon: this.greenIcon,
          draggable: false,
        }
      );
      marker.addTo(this.map).bindPopup(this.markers.destination.description ?? "unknown");
      bounds.extend(this.markers.destination.location)
    }

    // PARTICIPANTS
    if(this.markers) {
      this.markers.attendees.forEach(attendee => {
        if (!attendee.location || attendee.location.lat == 0) {
          return
        }
        const marker = Leaflet.marker(
          attendee.location,
          {
            icon: this.blueIcon,
            draggable: false,
          }
        );
        marker.addTo(this.map).bindPopup(attendee.description);
        bounds.extend(attendee.location)
      })
    }

    // STOPOVERS
    if(this.markers) {
      this.markers.stopovers.forEach(stopover => {
        if(!stopover.location || stopover.location.lat == 0) {
          return
        }
        const marker = Leaflet.marker(
          stopover.location,
          {
            icon: this.greyIcon,
            draggable: false,
          }
        );
        marker.addTo(this.map).bindPopup("" + stopover.dateTime + " - " + stopover.description);
        bounds.extend(stopover.location)
      })
    }

    this.map.fitBounds(
      bounds.pad(0.4),
      {
        paddingTopLeft: [0,40]
      }
    )
  }







  onMapMove($event: any) {
    //console.log(this.map.getCenter());
  }

  mapClicked($event: any) {
    //console.log($event.latlng.lat, $event.latlng.lng);
  }

  markerClicked($event: any, index: number) {
    console.log($event.latlng.lat, $event.latlng.lng);
  }

  markerDragEnd($event: any, index: number) {
    console.log($event.target.getLatLng());
  }
}
